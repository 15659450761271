import React from "react";

const SubCategoryLoadingComponent = () => {

    let LoadingItems = [1, 2, 3, 4, 5, 6]

    return (
        <>
            {LoadingItems.map((e) => {
                return (<div style={{ backgroundColor: 'rgb(250, 250, 250)', height: 52, margin: '15px 0px', border: '2px solid rgba(146, 207, 213, 1)', borderRadius: '8px', padding: "10px 15px", overflow: 'hidden' }} >
                </div >)
            })
            }
        </>
    )
}

export default SubCategoryLoadingComponent