import React, { useState, useEffect } from 'react'
import { Alert } from 'react-bootstrap'
import Buttons from '../../components/Buttons'
import CustomModel from '../../components/CustomModel'
import Api from '../../services/Api'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

const Subscription = () => {

    let opp = true
    let planExpired = false

    const [loading, setLoading] = useState(false);
    const [subscribed, setSubscribed] = useState([]);
    const [_alert, setAlert] = useState({ message: '', variant: '' });
    const [history, setHistory] = useState([])
    const [cancelLoading, setCancelLoading] = useState(false)

    const navigate = useNavigate()

    const [showPopup, setShowPopup] = useState(false);
    const [modelData, setModelData] = useState({ title: '', description: '', onClick: null });
    const [planType, setPlanType] = useState('m')

    const activeSubscription = useSelector(state => state.user.subscription);

    const isActive = (isActive) => {
        return (
            <div style={{ border: "1px solid black", backgroundColor: 'rgba(146, 207, 213, 1)', borderRadius: '20px', width: 'fit-content', padding: '5px 30px', fontFamily: "Dimbo" }}  >{isActive ? "Active" : 'Deactivated'}</div>
        )
    }

    const oppAccept = (val) => {
        opp = false
        setShowPopup(false)
        console.log('accepted')

    }
    const oppSubscriptionCancel = (val) => {
        setShowPopup(false);
        if (val) {
            setCancelLoading(true)
            Api.post('subscription/cancel', { "cancelled": 1 })
                .then((res) => {
                    if (res.success) {
                        // console.log(res)
                        let _subscibed = subscribed[0]
                        _subscibed.cancelled = 1;
                        setSubscribed([_subscibed])
                        setAlert({ message: "Subscription cancelled successfully!", varient: 'success' })
                    } else {
                        setAlert({ message: "Error occurred!", varient: 'danger' })
                    }
                }).catch((err) => {
                    setAlert({ message: "Error occurred!", varient: 'danger' })
                })
                .finally(() => {
                    window.scroll(0, 0)
                    setCancelLoading(false)
                })
        } else {

        }

    }
    const onCancelSubcomfirmation = (val) => {
        setModelData({ title: 'Confirmation!', description: 'Are you sure you want to cancel this subscription?', onClick: oppSubscriptionCancel })
        setShowPopup(true)
    }

    const getPlansDetails = () => {

        setLoading(true)
        Api.get('subscription/history')
            .then((res) => {
                if (res.success) {
                    setHistory(res.data)
                }
            }).catch(() => {
                setAlert({ message: 'Some error occurred!', variant: "danger" })
            })
            .finally(() => {
                setLoading(false)
            })

    }

    const navigateToSubscribe = () => {
        navigate('/subscription')
    }

    useEffect(() => {
        if (activeSubscription) {
            setSubscribed([activeSubscription])
        }
    }, [activeSubscription])


    useEffect(() => {
        getPlansDetails()
    }, [])

    return (
        <>
            <CustomModel showPopup={showPopup} setShowPopup={setShowPopup} title={modelData.title} description={modelData.description} onConfirm={modelData.onClick} />
            <h1 style={{ fontFamily: 'Dimbo', fontSize: '35px', margin: "20px  0", textAlign: 'center' }}>Subscription Management</h1>
            {_alert?.message.trim() !== '' && <Alert variant={_alert.variant} dismissible onClose={() => setAlert({ message: '', variant: '' })} >{_alert.message}</Alert>}
            {loading &&
                <>
                    <div style={{ backgroundColor: 'rgb(250, 250, 250)', borderRadius: '20px', height: '200px', width: '100%', margin: '5px 0px' }} ></div>
                    <div style={{ backgroundColor: 'rgb(250, 250, 250)', borderRadius: '20px', height: '400px', width: '100%', margin: '5px 0px' }} ></div>
                </>
            }
            <div className='plans_container_subscribed'>
                {(!loading && (subscribed?.length > 0)) && subscribed?.map((data, i) => {
                    let e = data?.subscription;
                    return (
                        <div key={i} className='plan_container'  >
                            <div className={`plan_container_main  ${(subscribed?.id == e.id) && " plan_container_main_margin"}  ${(!e.recommended) && "plan_container_main_margin_top"}`} style={{ backgroundColor: 'rgba(146, 207, 213, 0.05)' }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column', height: '100% ' }}>
                                    <div style={{ padding: '30px 25px' }}  >
                                        <div style={{ fontSize: '20px', fontWeight: '800' }} >{e.name}</div>
                                        <div style={{ fontSize: '15px', color: "rgba(0 , 0 , 0 , 0.6)" }} >{e.description}</div>
                                        <div>
                                            <div style={{ margin: '15px 0' }} >
                                                <div style={{ fontFamily: "Dimbo", fontSize: '35px', lineHeight: '35px' }} >{e.price}<span style={{ fontSize: '20px' }}>{e.plan_type == 'a' ? "/yearly" : '/monthly'}{!data.cancelled && "(Next Payment - " + data.next_payment_date + ")"}</span></div>
                                                {/* <div style={{ fontFamily: "Dimbo", fontSize: '35px', lineHeight: '35px' }} >{!parseInt(price) ? "Free" : price}<span style={{ fontSize: '20px' }}>{parseInt(price) ? (planType == 'a' ? "/yearly" : '/month') : ''}</span></div>
                                                <div style={{ color: "rgba(0 , 0 , 0 , 0.6)", fontSize: '15px', lineHeight: '15px' }}  >{parseInt(price) !== 0 && 'Billed monthly after 14 day trial'}</div> */}
                                            </div>
                                            {e?.features && e?.features?.map((ele, i) => {
                                                return (
                                                    <>
                                                        <div key={i} style={{ display: "flex", justifyContent: 'flex-start', alignItems: 'flex-start' }} >
                                                            <span style={{ color: '#92cfd5', marginTop: 4 }} ><img src={require('../../assets/plans-check.png')} alt='plans check' /></span>
                                                            <span style={{ marginLeft: '10px', lineHeight: '20px', fontSize: '15px', margin: 7, color: "rgba(0 , 0 , 0 , 0.6)" }}>{ele}</span>
                                                        </div>
                                                    </>
                                                )
                                            })}
                                        </div>
                                    </div>
                                    <div style={{ margin: 10 }} >
                                        <Buttons title={"Current"} onClick={() => { }} variant='default' />
                                    </div>

                                </div>
                            </div>
                            <div className='d-flex justify-content-between' style={{ margin: "0 10px " }}>
                                <Buttons title={"Downgrade"} onClick={() => { navigate('/subscription') }} variant='default' />
                                <Buttons title={"Upgrade"} onClick={() => { navigate('/subscription') }} variant='default' />
                            </div>
                            {!data.cancelled && <div className='cancel_button'  >
                                <div style={{ width: 'fit-content', margin: 'auto' }} >
                                    <Buttons title={'Cancel subscription'} onClick={onCancelSubcomfirmation} variant='transparent' loading={cancelLoading} />
                                </div>
                            </div>}
                        </div>
                    )
                })}
                {
                    !loading && subscribed.length == 0 &&
                    <div style={{
                        width: "fit-content", margin: 'auto', border: '1px solid red ', padding: "40px", borderRadius: 5, fontSize: 20, backgroundColor: 'peachpuff'
                    }} >{/** #92cfd5 */}
                        <div style={{ fontWeight: 'bold' }}>You don't have any active subscription!</div>
                        <>
                            <div className='mb-4' style={{ fontSize: '15px', fontWeight: 'normal', color: "rgb(112, 112, 112)" }} >Subscribe to use nestheads features!</div>
                            <Buttons title='Subscribe' onClick={navigateToSubscribe} />
                        </>
                    </div>
                }
            </div>

            {history.length > 0 &&
                <>
                    {/* <h1 className={`${subscribed ? "payment_history_margin" : "payment_history"}`}>Payment History</h1> */}
                    <h1 className={`${subscribed ? "payment_history_margin" : "payment_history"}`}>Payment History</h1>
                    <div className='Payment_history' >
                        <table style={{ width: '100%', border: '1px solid black' }} >
                            <thead>
                                <tr style={{ border: '1px solid black' }}>
                                    <th>Date</th>
                                    <th>Plan</th>
                                    <th>Amount</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {history.map((e, i) =>
                                    <tr key={i} >
                                        <td>Date-{e?.start_date}</td>
                                        <td>{e?.subscription?.name}</td>
                                        <td>{e?.amount || '000'}</td>
                                        <td>{isActive(e.status)}</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </>
            }
        </>
    )
}

export default Subscription