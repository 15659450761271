import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { Spinner } from "react-bootstrap";
import { Icon } from 'react-icons-kit';
import { trash2 } from 'react-icons-kit/feather/trash2';

const ChatListing = (props) => {

    const { i, e, deleteChat, delate_id, num, handlePublic } = props;
    const navigate = useNavigate();

    function formatChatDateTime(time) {
        let currentDate = new Date();
        let date = new Date(time);

        const options = {
            year: 'numeric',
            weekday: 'short',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
            timeZone: 'Asia/Kolkata'
        };

        if (
            date.getDate() === currentDate.getDate() &&
            date.getMonth() === currentDate.getMonth() &&
            date.getFullYear() === currentDate.getFullYear()
        ) {
            delete options.day;
        }

        if (
            date.getMonth() === currentDate.getMonth() &&
            date.getFullYear() === currentDate.getFullYear()
        ) {
            delete options.month;
        }

        if (date.getFullYear() === currentDate.getFullYear()) {
            delete options.year;
        }

        if (date.getDay() === currentDate.getDay()) {
            delete options.weekday;
        }

        return date.toLocaleString('en-IN', options);
    }

    return (
        <div className='character_listing' key={i} >
            <div className='character' onClick={() => { navigate(`/chat/${e.characters.id}-${e.characters.seo}/${e?.chat_messages?.chat_id}`) }}>
                <div className="character-image-wrapper"  >
                    <img src={e.characters.image} alt={e.characters.name} />
                </div>
                <div>
                    <div className='charater_title' >{e.characters.name} </div>
                    <div className='character_des' ><span style={{ color: 'black' }} >{formatChatDateTime(e?.chat_messages?.date_time) + ': '}</span>{e?.chat_messages?.message.length > num ? e?.chat_messages?.message.slice(0, num - 10) + '...' : e?.chat_messages?.message || ""} </div>
                </div>
            </div>
            <div style={{ display: 'flex' }} >
                <div title="delete chat" className='star-icon' style={{ alignSelf: 'end' }} onClick={() => deleteChat(e.chat_id, e.characters.id)} >
                    {(delate_id?.chat_id == e.chat_id && delate_id?.character_id == e.characters.id) ? <Spinner size='sm' /> : <Icon icon={trash2} size={22} />}
                </div>
                {/* <div title={e.visible ? "make chat private" : "make chat public"} onClick={() => handlePublic(e, e.visible == 0 ? true : false)} style={{ cursor: 'pointer', marginLeft: '10px' }} >
                    <Icon icon={e.visible == 1 ? ic_public_twotone : ic_public_outline} size={22} />
                </div> */}
            </div>
        </div>
    )
}

export default ChatListing