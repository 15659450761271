import React, { useEffect, useState } from 'react'
import Buttons from "./Buttons";
import AddHabit from "./AddHabit";
import Api from '../services/Api';
import SubCategoryLoadingComponent from './SubCategoryLoadingComponent';
import { Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import { setTitles } from '../actions/categoriesActions';
import { Helmet } from 'react-helmet';

const SubCategory = (props) => {

    const { categoryTitle } = props
    const [showPopup, setShowPopup] = useState(false);
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [deleating, setDeleating] = useState(null)
    const categories = useSelector(state => state.category.categories)
    // const titles = useSelector(state => state.category.titles)

    const titles = [];
    const [_helmet, sethelmet] = useState({ title: "", des: '' });
    const dispatch = useDispatch();

    useEffect(() => {
        if (parseInt(categoryTitle)) {

            sethelmet({ title: "Nestheads - " + categoryTitle.split("-")[1] });
            setLoading(true);
            Api.get('chat/items/' + parseInt(categoryTitle))
                .then((res) => {
                    if (res.success) {
                        setData(res.data)
                    }
                })
                .finally(() => {
                    setLoading(false)
                })
        }
    }, [categoryTitle])

    const onConfirm = (res) => {
        // console.log(res)
        setShowPopup(false)
        if (!res) {
            return
        } else {
            let character_id = categories.filter((e) => e.name.toLowerCase() == categoryTitle.toLowerCase())
            // console.log(character_id)

            let datas = {
                "category_id": character_id[0].id,
                "category_name": categoryTitle,
                "chat_title": res
            }
            Api.post("title/add", datas)
                .then((res) => {
                    if (res.success) {
                        let newData = [...titles, res.data]
                        dispatch(setTitles(newData))
                        toast.success("New " + categoryTitle + " created!")
                    } else {
                        toast.error(res.message || "Some error occurred!")
                    }
                })
                .catch((err) => {

                })
                .finally(() => {

                })
        }
    }

    const handleDelete = (id) => {
        setDeleating(id)
        Api.post("title/delete/" + id)
            .then((res) => {
                if (res.success) {
                    let _data = [...data]
                    let _updated = _data.filter((e) => e.id != id)
                    setData(_updated)
                } else {
                    toast.error(res.message || "Some error Occurred!")
                }
            })
            .catch((err) => {
            })
            .finally(() => {
                setDeleating(null)
            })
    }

    return (
        <div className='chat_page_container' >
            <Helmet>
                <title>{_helmet.title}</title>
                <meta name="description" content={_helmet.des} />
            </Helmet>
            <AddHabit showPopup={showPopup} setShowPopup={setShowPopup} onConfirm={onConfirm} title={categoryTitle.split("-")[1]} />
            <div style={{ fontFamily: 'Dimbo', fontSize: '28px' }} >My <span style={{ textTransform: 'capitalize' }} >{categoryTitle.split("-")[1]}</span></div>
            {loading &&
                <SubCategoryLoadingComponent />
            }
            {!loading &&
                data.length > 0 ? data.map((e, i) => (
                    <div key={i} style={{ margin: '15px 0px', border: '2px solid rgba(146, 207, 213, 1)', borderRadius: '8px', padding: "10px 15px", overflow: 'hidden' }} >
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center ' }}>
                            <div style={{ fontFamily: "Dimbo", fontSize: '20px', height: '30px', minWidth: '80px', overflow: 'hidden' }} >{e.chat_title} </div>
                            <div>
                                {(deleating == e.id) ? <Spinner size="sm" style={{ marginRight: '15px', }} /> : < img onClick={() => handleDelete(e.id)} src={require('../assets/delete_quest.png')} style={{ width: '18px', height: '18px', marginRight: '15px', cursor: "pointer" }} />}
                            </div>
                        </div>
                    </div >
                )) :
                !loading &&
                <div style={{ fontSize: '25px', fontFamily: 'Dimbo', textAlign: 'center', marginTop: '100px' }}>No {categoryTitle.split("-")[1]} available</div>
            }
            {/* {!loading && < div style={{ borderRadius: 10, display: 'flex', justifyContent: 'flex-end', marginTop: 10, cursor: 'pointer' }}>
                <Buttons title={"Add new " + categoryTitle} variant='transparent' onClick={() => setShowPopup(true)} />
            </div>} */}
        </div >
    )
}

export default SubCategory