import React, { useEffect, useState } from 'react'
import { Icon } from 'react-icons-kit';
import { eye } from 'react-icons-kit/feather/eye';
import { eyeOff } from 'react-icons-kit/feather/eyeOff';

const InputField = (props) => {

    const { lable, value, type, placeholder, onChange, disabled = false, dataKey } = props
    const [_type, setType] = useState(type);

    const eyeIcon = () => {
        if (_type == "password") {
            setType('text')
        } else if (_type == "text") {
            setType('password')
        }
    }

    useEffect(() => {
        setType(type)
    }, [type])
    return (

        <div className="fields">
            <span className="inputlabel" style={{ textTransform: 'capitalize' }} >{lable}</span>
            <input
                disabled={disabled}
                value={value}
                className="input-field"
                placeholder={placeholder}
                type={_type}
                onChange={(e) => onChange(dataKey, e.target.value)}
            />
            {type == "password" && <div onClick={eyeIcon} style={{ cursor: 'pointer', position: 'absolute', right: 15, bottom: 20, color: _type == "password" ? '#ccc' : '#000' }} > <Icon icon={_type == "password" ? eyeOff : eye} size={18} /></div>}
        </div>
    )
}

export default InputField