import React, { useEffect, useState, useRef } from "react";
import Api from "../services/Api";
import Buttons from "./Buttons";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Icon from "react-icons-kit";
import { ic_public_outline } from 'react-icons-kit/md/ic_public_outline'
import { ic_public_twotone } from 'react-icons-kit/md/ic_public_twotone'
import { toast } from "react-toastify";
import InfiniteScroll from 'react-infinite-scroller';
import { Spinner } from "react-bootstrap";
import QuestListing from "./QuestLisitng";
import { Helmet } from "react-helmet";


const Quest = () => {

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [readmore, setReadMore] = useState([]);
    const [deleteids, setdeletedids] = useState(null)
    const navigate = useNavigate();

    const isLogged = useSelector((state) => state.user.isLogged);
    const user = useSelector((state) => state.user.data);

    const pageRef = useRef(0)
    const hasMoreRef = useRef(false)

    function secondsToHms(d) {

        const minutes = Math.floor(d / 60);
        const seconds = d - minutes * 60;

        return `${minutes > 0 ? minutes + " min" : ''}${seconds > 0 ? (minutes > 0 ? ", " : "") + seconds + " sec" : ''}`
    }

    const navigateTo = (page) => {
        if (isLogged) {
            navigate(page)
        } else {
            navigate('/login')
        }
    }

    const handlePublic = (chat_id, val) => {
        Api.post('chat/set-visibility/' + chat_id, { "visible": val ? 1 : 0 })
            .then((res) => {
                if (res.success) {
                    let _data = []
                    toast.success(val ? "Quest is public!" : 'Quest is private!')
                    data.map((e) => {
                        if (e.chat_id == chat_id) {
                            e.visible = val
                            _data.push(e)
                        } else {
                            _data.push(e)
                        }
                    })
                    setData(_data)
                } else {
                    toast.error(res?.message || "some error occurred!")
                }
            })
            .catch(() => {
                toast.error("some error occured")
            })
    }
    const deleteChat = (chat_id, character_id, index) => {
        // setDeleteID({ chat_id, character_id })
        // setAlert({ varient: "", msg: '' })
        setdeletedids(index)
        Api.post('chat/delete', {
            chat_id,
            character_id
        }).then((res) => {
            if (res.success) {
                let _chats = [];

                data.map((e) => {
                    if (e.character.id == character_id && e.chat_id == chat_id) {
                    } else {
                        _chats.push(e)
                    }
                })
                setData(_chats);
                toast.success("Quest deleted!");
            } else {
                toast.error(res.message || 'some error occurred!')
            }
        })
            .catch((err) => {
                toast.error('some error occurred!')
            })
            .finally(() => {
                setdeletedids(null)
            })

    }

    useEffect(() => {
        if (user.id) {
            setLoading(true)
            // console.log(user)
            Api.get(`chat/quests?limit=2&page=1&user_id=${user.id}`, {
                "user_id": user.id
            })
                .then((res) => {
                    if (res.success) {
                        console.log(res.data.next)
                        if (res.data.next) {
                            pageRef.current = 2
                        }
                        hasMoreRef.current = res.data.next
                        setData(res.data.list)
                    } else {
                        hasMoreRef.current = false
                    }
                }).catch(() => {
                    hasMoreRef.current = false
                })
                .finally(() => {
                    setLoading(false)
                })
        }
    }, [user.id])

    const loadMore = () => {
        Api.get(`chat/quests?limit=2&page=${pageRef.current}&user_id=${user.id}`)
            .then((res) => {
                if (res.success) {
                    hasMoreRef.current = res.data.next
                    let _data = [...data, ...res.data.list]
                    setData(_data)
                    pageRef.current++
                } else {
                    hasMoreRef.current = false
                }
            })
            .catch(() => {
                hasMoreRef.current = false
            })
    }
    const updateSummary = (chat_id, character_id, updatedsummary) => {
        Api.post('chat/update/summary',
            {
                "chat_id": chat_id,
                "character_id": character_id,
                "summary": updatedsummary
            })
            .then((res) => {

                if (res.success) {
                    let _chats = [];
                    data.map((e) => {
                        if (e.character.id == character_id && e.chat_id == chat_id) {
                            e.summary = updatedsummary;
                            _chats.push(e);
                        } else {
                            _chats.push(e);
                        }
                    })
                    setData(_chats);
                    toast.success('summary updated!');

                } else {
                    toast.error(res?.message || 'some error occurred!');
                }
            })
            .catch((err) => {
                toast.error(err?.message || 'some error occurred!');
            })
            .finally(() => {
            })
    }

    return (
        <div className="chat_page_container" >
            <Helmet>
                <title>{'Nestheads - Quests'}</title>
            </Helmet>
            <div style={{ fontFamily: 'Dimbo', fontSize: '28px' }}>My Quests</div>
            {
                loading &&
                <div style={{ margin: '20px' }} >
                    <div style={{ backgroundColor: 'rgb(250, 250, 250)', borderRadius: '20px', height: '200px', width: '100%', margin: '5px 0px' }} ></div>
                    <div style={{ backgroundColor: 'rgb(250, 250, 250)', borderRadius: '20px', height: '300px', width: '100%', margin: '5px 0px' }} ></div>
                </div>
            }
            {!loading && data.length == 0 ?
                <div style={{ fontSize: '25px', fontFamily: 'Dimbo', textAlign: 'center', marginTop: '100px' }}>No quests available</div>
                :
                <InfiniteScroll
                    pageStart={1}
                    loadMore={loadMore}
                    hasMore={hasMoreRef.current}
                    loader={<div className="loader d-flex justify-content-center align-items-center my-3 " key={0}><Spinner /></div>}
                >
                    {
                        !loading && data.length > 0 && data.map((e, i) => {
                            return (
                                <QuestListing updateSummary={updateSummary} i={i} deleteids={deleteids} deleteQuest={deleteChat} e={e} readmore={readmore} setReadMore={setReadMore} handlePublic={handlePublic} varient={'private'} />
                                // < div onClick={() => { }} className=' my-4 my-lg-5 px-3 px-lg-4 px-md-3 py-4 py-lg-5 border border-dark border-4' style={{ borderRadius: '30px', overflow: "hidden", boxShadow: "0px 5px 15px 2px #eee" }} >
                                //     <div className="d-flex justify-content-between">
                                //         <div className="d-flex align-items-center" >
                                //             <div className='mx-1 mx-sm-2'>
                                //                 <img referrerpolicy="no-referrer" src={e?.character?.photo || require("../assets/user.png")} className='quest_header_user_img' />
                                //             </div>
                                //             <div>
                                //                 <div className="quest_slider_model_seo lh-1 " style={{ fontFamily: 'Dimbo', overflow: 'hidden' }} >{e.character.name}</div>
                                //                 {/* <div className='homepage_slider_model_seo'  >@{e.user.username}</div> */}
                                //             </div>
                                //         </div>
                                //         <div className="d-flex align-items-center"  >
                                //             <div className='mx-1 mx-sm-2'>
                                //                 <img src={require("../assets/history.png")} className='quest_header_img' />
                                //             </div>
                                //             <div>
                                //                 <div className="fw-bold lh-1 homepage_slider_time " >Best Time: {secondsToHms(e.character.best_time)}</div>
                                //                 <a href={`${"/models/" + e.character.seo}`} className='homepage_slider_model_seo' style={{ cursor: 'pointer' }} onClick={(ele) => { ele.preventDefault(); navigate('/models/' + e.character.seo) }}>@{e.character.seo}</a>
                                //             </div>
                                //         </div>
                                //     </div>
                                //     {e?.tags?.length > 0 &&
                                //         <div className="quest_tags_list">
                                //             {e.tags.map((e, i) => <img key={i} src={e.image} alt="quest" />)}
                                //         </div>
                                //     }
                                //     <div className="" style={{ marginTop: e.tags.length > 0 ? "0px" : '20px' }}>
                                //         {e.summary.length > 500 && !readmore.includes(e.id) ?
                                //             <div className="fs-6" >
                                //                 {e.summary.slice(0, 460)}<span onClick={() => setReadMore([...readmore, e.id])} style={{ color: 'gray', cursor: 'pointer' }}> Read more...</span>
                                //             </div>
                                //             : <div className="fs-6" >{e.summary}{e.summary.length > 500 && <span onClick={() => setReadMore(prev => prev.filter((ele) => ele != e.id))} style={{ color: 'gray', cursor: 'pointer' }}> show less.</span>}</div>}
                                //         <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
                                //             {/* <Buttons title={"Play Now"} onClick={() => handlePublic(e.chat_id, false)} /> */}
                                //             <div title={e.visible ? "make chat private" : "make chat public"} onClick={() => handlePublic(e.chat_id, e.visible == 0 ? true : false)} style={{ cursor: 'pointer', marginLeft: '10px' }} >
                                //                 <Icon icon={e.visible == 1 ? ic_public_twotone : ic_public_outline} size={25} />
                                //             </div>
                                //         </div>
                                //     </div>
                                // </div>
                            )
                        })
                    }
                </InfiniteScroll>}
        </div >
    )
}

export default Quest