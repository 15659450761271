import React, { useEffect, useState, useRef } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import ChatMessage from './ChatMessage';
import Api from '../services/Api';
import { Helmet } from 'react-helmet';
import WebcamCapture from './WebCam';
import CustomModel from './CustomModel';
import { useDispatch, useSelector } from 'react-redux';
import { setCategoriesdata, addTitle } from '../actions/categoriesActions';
import { toast } from 'react-toastify';
import PrecentageComponent from './PercentageComponent';
import Buttons from './Buttons';
import { setChatdata, updateChatdata } from '../actions/chatActions';
import { chatsLimitDecrement } from '../actions/userAction';

let defaultimg = require('../assets/user.png');

const ChatComponent = (props) => {

    const user = useSelector((state) => state?.user);
    const isLogged = useSelector((state) => state?.user.isLogged);
    const defaultCharacter = useSelector(state => state.characotors.default_charactor)
    const chatsData = useSelector(state => state.userchats.chats);
    const activeSubscription = useSelector(state => state.user.subscription);

    const { chat_id = 0, character_id = null, input = 'fixed', varient = 'default' } = props;
    const [showAbout, setShowAbout] = useState(false);
    const [notAvilable, setNotAvilable] = useState(false);
    const [modelNotAvilable, setModelNotAvilable] = useState(false);
    const [loading, setLoading] = useState(false);
    const [character, setCharacter] = useState(null);
    const [message, setMessage] = useState("");
    const [chatMessages, setChatMessages] = useState([]);
    const [showField, setShowField] = useState(true);
    const [sending, setSending] = useState(false);
    const [chatID, setChatID] = useState(0);
    const [openCamera, setOpenCamera] = useState(false);
    const [modelData, setModelData] = useState({ title: '', description: '', onClick: null, varient: "default" });
    const [showPopup, setShowPopup] = useState(false);
    const [tags, setTags] = useState({});
    const [creatingCategoryLoading, setCreatingCategoryLoading] = useState(false);
    const [fileToUpload, setFileToUpload] = useState(null);
    const [isCamera, setIsCamera] = useState(false);
    const [percentage, setPercentage] = useState("0%");
    const [questCreating, setQuestCreating] = useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const location = useLocation();
    let { redirectmessage = null } = location?.state || { redirectmessage: null };

    const inputref = useRef();
    const activepopup = false;

    const openINput = () => {
        inputref.current.click()
    }

    const handleQuests = (res) => {
        if (!res) {
            setShowPopup(false)
            setModelData({ title: "", description: "", onClick: null, varient: '' })
            if (tags.tag_name.length) {
                let _tags = { ...tags };
                _tags.tag_name.shift();
                setTags(_tags);
            }
        } else {
            setCreatingCategoryLoading(true)
            Api.post('chat/category',
                {
                    "chat_id": JSON.stringify(chatID),
                    "tag_id": JSON.stringify(tags.tag_name[0].id),
                    "tag": tags.tag_name[0].name,
                    "chat_title": tags.chat_heading
                }
            )
                .then((res) => {
                    if (res.success) {
                        dispatch(setCategoriesdata(res.data.categories))
                        dispatch(addTitle(res.data?.chat_title))
                    }
                })
                .finally(() => {
                    setShowPopup(false)
                    setCreatingCategoryLoading(false)
                    setModelData({ title: "", description: "", onClick: null, varient: '' })
                    if (tags.tag_name.length) {
                        let _tags = { ...tags };
                        _tags.tag_name.shift();
                        setTags(_tags);
                    }
                })
        }
        setTags({})
    }

    useEffect(() => {
        if (!activepopup) {
            if (Object.keys(tags).length > 0) {
                let APIsArray = []

                tags?.tag_name?.map((e) => {
                    APIsArray.push(
                        Api.post('chat/category', {
                            "chat_id": JSON.stringify(chatID),
                            "tag_id": JSON.stringify(e.id),
                            "tag": e.name,
                            "chat_title": tags.chat_heading
                        }))
                })
                console.log(APIsArray)
                Promise.all(APIsArray)
                    .then((res) => {
                        // console.log(res)
                        dispatch(setCategoriesdata(res[res.length - 1].data.categories))
                    })
                    .finally(() => {
                        setTags({})
                    })
            }
        } else {

            setTimeout(() => {
                checkAnother()
            }, 1000)
        }
    }, [tags])


    const checkAnother = () => {
        // console.log(tags[1])
        if (tags?.tag_name?.[0]) {
            setModelData({
                title: character.name, image: character.image, color: character.color, description: "We've noticed you're talking a lot about " + tags.chat_heading + ". Would you like to categorize this as a '" + tags.tag_name[0].name + "'?", onClick: handleQuests, varient: 'quest'
            })
            setShowPopup(true);
        }
    }

    const subscriptionExpired = (res) => {
        if (res) {
            navigate('/subscription', { state: { from: location.pathname, redirectmessage: message } })
            setModelData({ title: '', description: '', onClick: null, varient: "" })
            setShowPopup(false)
        } else {
            setModelData({ title: '', description: '', onClick: null, varient: "" })
            setShowPopup(false)
        }
    }

    const send = (e) => {
        e.preventDefault();
        if (message.trim() == "" && fileToUpload == null) {
            return
        }
        if (sending) {
            return
        }
        if (!isLogged) {
            navigate('/login', { state: { from: "homepage", redirectmessage: message } });
            return
        }
        if (activeSubscription == null) {
            navigate('/subscription', { state: { from: varient == 'homepage' ? '/' : input == "static" ? "/chat/category/nestheads" : "/chat", redirectmessage: message } })
            return
        }
        // alert(JSON.stringify(activeSubscription))
        if ((!activeSubscription.chats_limit || activeSubscription.chats_limit == "" || activeSubscription.chats_limit == 0) && chatID == 0) {
            setModelData({
                title: "Chats limit Reached!", description: "Chats limit Reached! Please subscribe to use nestheads!", onClick: subscriptionExpired
            })
            setShowPopup(true)
            return
        }
        let obj = {
            dataTime: new Date().getTime(),
            message: message,
            by: 'U',
            files: fileToUpload
        };

        let Chatobj = {
            dataTime: new Date().getTime(),
            message:
                "Typing...",
            by: 'C'
        };

        setChatMessages(prev => [...prev, obj, Chatobj]);

        setSending(true)
        let data = new FormData()
        data.append("chat_id", chatID)
        data.append("character_id", isLogged ? character_id : defaultCharacter.id)
        data.append("message", message)
        if (typeof fileToUpload == 'string' && fileToUpload?.includes("data:image")) {
            data.append("file", fileToUpload)
        } else if (fileToUpload) {
            data.append("file", fileToUpload[0])
        }
        if (isCamera) {
            data.append("camera", true)
        }

        let headers = {
            "content-type": fileToUpload ? "multipart/form-data" : 'application/json',
            'Authorization': "Bearer " + localStorage.getItem('nestheadToken'),
            'Accept': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Max-Age': 600,
        }

        // setMessage('')
        // setSelectedFiles(null)
        // setSelectedImages(null)

        // return
        let loaded = true
        Api.post('chat/sendMessage', data, { headers })
            .then((resData) => {
                // console.log(resData);
                let showModel = false
                if (resData.success) {
                    if (resData.data.list.allow_verify) {
                        setModelNotAvilable(false);
                    } else {
                        setModelNotAvilable(true);
                    }
                    if (resData.data.list?.percentage) {
                        setPercentage(resData.data.list.percentage + "%");
                    }
                    let res = resData.data.list.message;
                    let msg = res.split(' ');
                    let _text = '';
                    let end = msg.length;
                    let reached = 0;
                    // setChatId(resData.data.list.chat_id)
                    if (chatID == 0) {
                        dispatch(chatsLimitDecrement())
                    }
                    setChatID(resData.data.list.chat_id)
                    dispatch(updateChatdata(resData.data.list));
                    if (input == 'static' && varient != 'homepage') {
                        navigate('/chat/' + character_id + "/" + resData.data.list.chat_id)
                    }
                    loaded = false
                    let interval = setInterval(() => {
                        if (reached == end) {
                            // console.log(tags)
                            if (resData.data.list?.tags) {
                                setTags(resData.data.list?.tags)
                            }
                            clearInterval(interval)
                            loaded = true
                            setSending(false)
                        } else {
                            _text = _text + msg[reached] + " ";
                            reached++
                            let obj = {
                                dataTime: new Date().getTime(),
                                message: _text,
                                // image: ['https://api.nestheads.app/assets/images/1700570680Conscience.png', 'https://api.nestheads.app/assets/images/o2wrShock.PNG'],
                                // files: ['https://docs.google.com/document/d/1WPa1cdLrl9kSeYy-24q2FDOA0EhJn0KCqmIImaPnJyE/edit', 'https://www.nestheads.app/static/media/nestheads.7e22ca9de167beff1293.mp4'],
                                by: "C"
                            }
                            setChatMessages(prev => {
                                let _prev = prev
                                _prev[prev.length - 1] = obj
                                return [..._prev]
                            })
                        }
                    }, 10)
                } else {
                    // if (resData.message = 'The audio file could not be decoded or its format is not supported') {
                    //     toast.error(resData.message);
                    // }

                    // toast.error(resData.message);
                    // if (input == 'static') {
                    //     navigate('/chat')
                    // }

                    let obj = {
                        dataTime: new Date().getTime(),
                        message: resData.message || "Some error occurred!",
                        by: "C"
                    }

                    setChatMessages(prev => {
                        let _prev = prev
                        _prev[prev.length - 1] = obj
                        return [..._prev]
                    })
                }
            })
            .catch((err) => {
                console.log(err)

                let obj = {
                    dataTime: new Date().getTime(),
                    message: "Some error occurred Please try again!",
                    by: "C"
                }

                setChatMessages(prev => {
                    let _prev = prev
                    _prev[prev.length - 1] = obj
                    return [..._prev]
                })
            })
            .finally(() => {
                if (loaded) {
                    setSending(false)
                }
            })
        setMessage('')
        setFileToUpload(null)
        setIsCamera(false)
    }


    useEffect(() => {

        console.log(character_id, chat_id)
        // setModelData({ title: "New Nestheads", description: "We've noticed you're talking a lot about future aspirations. Would you like to categorize this as a 'Goal'?", onClick: handleQuests })
        if (redirectmessage != null && varient == 'homepage') {
            setCharacter(defaultCharacter);
            return
        }

        if (character_id == null || character_id == 0) {
            setShowField(false);
            setLoading(true);
            setTimeout(() => {
                setLoading(false);
            }, 1000)
        } else {
            if (character_id == "default") {
                setCharacter(defaultCharacter)
            } else {
                setChatID(chat_id)
                setChatMessages([])
                setLoading(true)
                Api.post(`characters/detail/${parseInt(character_id)}`)
                    .then((res) => {
                        // console.log(character_id)
                        // console.log(res.data)
                        if (res.success) {
                            setCharacter(res.data);
                            if (res.data.status == null) {
                                setModelNotAvilable(true);
                                setShowField(false)
                            } else {
                                setModelNotAvilable(false);
                            }
                        } else {
                            // navigate('/models');
                            setCharacter(null);
                            setModelNotAvilable(true);
                            setShowField(false)
                        }
                    })
                    .catch(() => {
                        setCharacter(null)
                        setModelNotAvilable(true);
                        setShowField(false)
                    }).finally(() => {
                        if (!chat_id) {
                            setLoading(false)
                        }
                    })
            }
            if (chat_id && redirectmessage == null) {
                setLoading(true)
                Api.post(`chat/detail`,
                    {
                        "chat_id": chat_id,
                        "character_id": JSON.stringify(parseInt(character_id))
                    })
                    .then((res) => {
                        // console.log(res.data)
                        if (res.success && res.data != null) {
                            setPercentage(res?.data?.[res.data.length - 1]?.percentage);
                            setChatMessages(res.data);
                            setNotAvilable(false);
                            setShowField(true)

                        } else {
                            // console.log('chat not available')
                            setNotAvilable(true);
                            setChatMessages([]);
                            setShowField(false);
                        }
                    })
                    .catch(() => {
                        setChatMessages([]);
                        setShowField(false);

                    }).finally(() => {
                        setLoading(false);
                    })
            } else {
                setShowField(true)
            }
        }
    }, [chat_id, character_id, defaultCharacter])

    useEffect(() => {
        if (redirectmessage != null) {
            setCharacter(defaultCharacter)
            setMessage(redirectmessage)
        }
    }, [location])

    const removeFile = (e) => {
        setIsCamera(false)
        setFileToUpload(null)
    }

    function getExtension(filename) {
        var parts = filename.split('.');
        return parts[parts.length - 1];
    }
    function isImage(filename) {
        var ext = getExtension(filename);
        switch (ext.toLowerCase()) {
            case 'jpg':
            case 'gif':
            case 'bmp':
            case 'png':
            case 'jpeg':
                //etc
                return true;
        }
        return false;
    }

    function isVideo(filename) {
        var ext = getExtension(filename);
        switch (ext.toLowerCase()) {
            case 'm4v':
            case 'avi':
            case 'mpg':
            case 'mp4':
                // etc
                return true;
        }
        return false;
    }

    const onSelectFiles = (e) => {

        let formats = ['mp3', 'mp4', 'mpeg', 'mpga', 'm4a', 'wav', 'webm']

        if (!isImage(Array.from(e)[0].name)) {
            if (Array.from(e)[0].size / 1024 > 24000) {
                toast.error('File size too big!')
                return
            } else if (!formats.includes(Array.from(e)[0].type.split("/")[1])) {
                toast.error('File type not supported!')
                return
            }
            else {
                setFileToUpload(e)
                return
            }
        }
        setFileToUpload(e)
    }

    useEffect(() => {
        if (!loading) {

            if (varient == 'homepage') {
                let height = document.getElementById("homepage_messages_containers").scrollHeight
                document.getElementById("homepage_messages_containers").scrollTo({ top: height, behavior: 'smooth' });

            } else {


                let _height = document.querySelector('.start_chat')?.offsetHeight || 0;
                if (input == "static") {
                    document.getElementById('mynestheads_chatComponent').scrollTo(0, _height)
                } else {
                    window.scrollTo(0, _height);
                }
            }
        }
    }, [chatMessages, fileToUpload])

    const onCloseCamera = () => {
        setOpenCamera(false)
        document.body.style.overflow = 'initial'
    }

    const handleBattle = () => {
        setQuestCreating(true)
        Api.post("chat/set-quest", {
            "chat_id": parseInt(chatID),
            "character_id": parseInt(character_id)
        })
            .then((res) => {
                if (res.success) {
                    let _chats = [];
                    chatsData.map((e) => {
                        if (e.chat_messages == null || (e.character_id == character_id && e.chat_id == chatID)) {
                            console.log(e);
                        } else {
                            _chats.push(e);
                        }
                    })
                    dispatch(setChatdata(_chats))
                    setTimeout(() => {
                        navigate('/chat/category/quests')
                    }, 10)
                    toast.success("Quest created!")
                } else {
                    toast.error(res.message)
                }
            })
            .catch(() => {
                toast.error("Error occurred!")
            })
            .finally(() => {
                setQuestCreating(false)
            })
    }

    const onCapture = (e) => {
        setIsCamera(true)
        setFileToUpload(e)
    }

    const handleOpenCamera = () => {
        setOpenCamera(true)
        document.body.style.overflow = 'hidden'
    }

    if (loading) {
        return (
            <div style={{ margin: '20px' }} >
                <div style={{ backgroundColor: 'rgb(250, 250, 250)', borderRadius: '20px', height: '200px', width: '100%', margin: '5px 0px' }} ></div>
                <div style={{ backgroundColor: 'rgb(250, 250, 250)', borderRadius: '20px', height: '300px', width: '100%', margin: '5px 0px' }} ></div>
            </div>
        )
    }

    return (
        <div style={{ height: '100%', position: "relative", overflow: varient == 'homepage' ? "hidden" : 'initial' }}>
            <WebcamCapture onClose={onCloseCamera} openCamera={openCamera} onCapture={onCapture} />
            <CustomModel loading={creatingCategoryLoading} showPopup={showPopup} setShowPopup={setShowPopup} title={modelData.title} description={modelData.description} onConfirm={modelData.onClick} image={modelData?.image || null} varient={modelData.varient} color={modelData.color} />

            {varient != 'homepage' ?
                <div className='start_chat'>
                    <Helmet>
                        <title>{character?.seo || ""}</title>
                        <meta property='description' content={character?.description || ""} />
                    </Helmet>

                    {varient != "landing" &&
                        <div className={`${input == 'static' ? "main_character_container_static" : "main_character_container"}`} >
                            <div className='chat_img_container'>
                                {character ?
                                    <>
                                        <div className={`${showAbout ? 'about_character_active' : "about_character"}`}> Click on Character to learn more.</div>
                                        {(chatMessages == null || chatMessages?.length == 0) ?
                                            <a href={`/models/${character?.seo}`} onClick={e => e.preventDefault()}>
                                                <img src={character?.image || ''} alt={character?.name} className='chat_character_image' onMouseEnter={() => setShowAbout(true)} onMouseLeave={() => setShowAbout(false)} onClick={() => navigate(`/models/${character?.seo}`)} />
                                            </a>
                                            :
                                            <a href={`/models/${character?.seo}`} onClick={e => e.preventDefault()} >
                                                <img src={character?.image || ''} alt={character?.name} className='chat_character_image_small' onMouseEnter={() => setShowAbout(true)} onMouseLeave={() => setShowAbout(false)} onClick={() => navigate(`/models/${character?.seo}`)} />
                                            </a>
                                        }
                                    </>
                                    :
                                    <div>
                                        <div style={{}} className='model_not_available' ></div>
                                    </div>
                                }
                            </div >
                            <div className={`${chatMessages?.length > 0 ? "starting_box_small" : 'starting_box'}`}>
                                <div style={{ color: character?.color || 'black' }}>{character?.name || "Character"}:</div>
                                <div>Hey there! What’s on your mind?</div>
                                {
                                    // (chatID > 0 && parseInt(percentage) > 0) && 
                                    <div>
                                        <PrecentageComponent percent={parseInt(percentage) + '%'} />
                                    </div>}
                            </div>
                        </div>
                    }
                    {/* {
                        varient == 'landing' &&
                        <>
                            <div className='d-flex justify-content-end my-5'>
                                <Buttons variant='' title={"Make your own"} onClick={() => { }} />
                                <div className='mx-4'>
                                    <Buttons variant='transparent' title={"AI Make it"} image={require("../assets/robot.png")} onClick={() => { }} />
                                </div>
                            </div>
                            <div className='d-flex flex-column '>
                                <div className='d-flex align-self-start align-items-end mb-3 msg_container_static_message'>
                                    <div style={{ width: '60px', height: '60px', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: "10px", border: '2px solid black', borderRadius: '50%' }} >
                                        <img src={require('../assets/Character/Confirmation Bias.png')} style={{ maxWidth: '90%' }} />
                                    </div>
                                    <div className='message_container_landing_left' style={{ marginLeft: "60px", padding: '12px', backgroundColor: 'rgba(37, 33, 52, 1)', color: 'white', borderRadius: '10px' }} >
                                        <div style={{ fontSize: '20px' }} ><div className='text-start'>{character?.name || "model"}</div>
                                            Hey there, what’s on your mind?</div>
                                    </div>
                                </div>
                                <div className='d-flex align-self-end align-items-end mb-3 msg_container_static_message '>
                                    <div className='message_container_landing_right' style={{ marginRight: "60px", padding: '12px', backgroundColor: 'rgba(159, 205, 212, 1)', color: 'white', borderRadius: '10px' }} >
                                        <div style={{ fontSize: '20px' }}  >Some answer will be here
                                            2  rows</div>
                                    </div>
                                    <div style={{ width: '60px', height: '60px', display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'hidden', border: '2px solid black', borderRadius: '50%' }} >
                                        <img src={user?.data?.photo ? user.data.photo : defaultimg} style={{ maxWidth: '100%', objectFit: 'cover' }} />
                                    </div>
                                </div>
                            </div>
                        </>
                    } */}
                    <div className={`${input == 'static' ? "chat_messages_container_static" : 'chat_messages_container'}`} style={{ marginBottom: parseInt(percentage) > 75 ? '130px' : '100px' }}>
                        {
                            !modelNotAvilable &&
                                notAvilable ?
                                <div style={{ fontFamily: 'Dimbo', fontSize: '20px', marginTop: '150px' }}>Chat not Available</div>
                                :
                                chatMessages && chatMessages.map((e, i) => {
                                    return (
                                        <div key={i} style={{ display: 'flex', flexDirection: 'column' }}>
                                            <ChatMessage msgdata={e} character={character} varient={varient} />
                                        </div>
                                    )
                                })
                        }
                    </div>
                </div >
                :
                <>
                    <div className='' style={{ position: 'absolute', top: 0, width: '100%', padding: '20px', zIndex: 10, backgroundColor: "white", }}>
                        <PrecentageComponent percent={isLogged ? percentage : "0"} height="20" color='rgba(255, 87, 66, 1)' showPercentage={false} title={percentage > 75 ? "" : "Needs More Information"} />
                    </div>
                    {/* {percentage > 75 && < div className='d-flex justify-content-end' style={{ position: 'absolute', top: '50px', right: '0px', zIndex: 2 }}>
                        <Buttons variant='' title={"Make your own"} onClick={() => { }} />
                        <div className='mx-4'>
                            <Buttons variant='transparent' title={"AI Make it"} image={require("../assets/robot.png")} onClick={() => { }} />
                        </div>
                    </div>} */}
                    <div className='d-flex flex-column h-100 px-2 px-md-5 home_messages_container ' id={'homepage_messages_containers'} >
                        <div className='d-flex align-self-start align-items-end mb-3 msg_container_static_message'>
                            <div style={{ width: '70px', minWidth: '70px', height: '70px', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: "10px", border: '2px solid black', borderRadius: '50%' }} >
                                <img src={character?.image} style={{ maxWidth: '90%' }} />
                            </div>
                            <div className='message_container_landing_left' style={{ marginLeft: "60px", padding: '12px', backgroundColor: 'rgba(37, 33, 52, 1)', color: 'white', borderRadius: '10px' }} >
                                <div style={{ fontSize: '20px' }} ><div className='text-start'>{character?.name || "model"}:</div>
                                    Hey there, what’s on your mind?</div>
                            </div>
                        </div>
                        {/* {!isLogged &&
                            <div className='d-flex align-self-end align-items-end mb-3 msg_container_static_message'>
                                <div className='message_container_landing_right' style={{ marginRight: "60px", padding: '12px', backgroundColor: 'rgba(159, 205, 212, 1)', color: 'white', borderRadius: '10px' }} >
                                    <div style={{ fontSize: '20px' }}  >Some answer will be here
                                        2  rows</div>
                                </div>
                                <div style={{ width: '70px', minWidth: '70px', height: '70px', display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'hidden', border: '2px solid black', borderRadius: '50%' }} >
                                    <img src={user?.data?.photo ? user.data.photo : defaultimg} style={{ maxWidth: '100%', objectFit: 'cover' }} />
                                </div>
                            </div>
                        } */}
                        {/* </div> */}
                        {notAvilable ?
                            <div style={{ fontFamily: 'Dimbo', fontSize: '20px' }}>Chat not Available</div>
                            : chatMessages.length > 0
                            && chatMessages.map((e, i) => {
                                return (
                                    <div key={i} style={{ display: 'flex', flexDirection: 'column' }}>
                                        <ChatMessage msgdata={e} character={character} varient={"homepage"} />
                                    </div>
                                )
                            })
                        }
                    </div>
                </>
            }
            {
                modelNotAvilable &&
                <div style={{ fontFamily: 'Dimbo', fontSize: '22px', textAlign: 'center', marginBottom: '50px' }} >Model not Available!</div>
            }
            {
                (showField || varient == 'homepage') &&
                <form onSubmit={sending ? (e) => { e.preventDefault() } : send} className={`chat_field ${input == "static" && " chat_field_static"}`} style={{ flexDirection: 'column' }}>
                    <div style={{ flex: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                        {/* <div style={{ border: '1px solid rgba(30, 30, 30, 0.4)', height: 46, borderRadius: 30, width: "fit-content", display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '0 18px' }}>
                                <div style={{ height: 25, overflow: 'hidden', position: 'relative', cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                                    <input type='file' style={{ height: 20, width: 20, margin: 0, opacity: 0, position: 'absolute', padding: 0, cursor: 'pointer' }} accept="image/png, image/gif, image/jpeg" onChange={(e) => onSelectFiles(e.target.files)} />
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-image" viewBox="0 0 16 16">
                                        <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0" />
                                        <path d="M2.002 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2zm12 1a1 1 0 0 1 1 1v6.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12V3a1 1 0 0 1 1-1z" />
                                    </svg>
                                </div>
                                <div style={{ width: 15, height: 24, margin: '0 12px', cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <input type='file' style={{ height: `8`, width: 18, margin: 0, opacity: 0, position: 'absolute', padding: 0, cursor: 'pointer' }} multiple onChange={(e) => onSelectFiles(e.target.files)} />
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M364.2 83.8c-24.4-24.4-64-24.4-88.4 0l-184 184c-42.1 42.1-42.1 110.3 0 152.4s110.3 42.1 152.4 0l152-152c10.9-10.9 28.7-10.9 39.6 0s10.9 28.7 0 39.6l-152 152c-64 64-167.6 64-231.6 0s-64-167.6 0-231.6l184-184c46.3-46.3 121.3-46.3 167.6 0s46.3 121.3 0 167.6l-176 176c-28.6 28.6-75 28.6-103.6 0s-28.6-75 0-103.6l144-144c10.9-10.9 28.7-10.9 39.6 0s10.9 28.7 0 39.6l-144 144c-6.7 6.7-6.7 17.7 0 24.4s17.7 6.7 24.4 0l176-176c24.4-24.4 24.4-64 0-88.4z" /></svg>
                                </div>
                                <div onClick={handleOpenCamera} style={{ height: 24, cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-camera-fill" viewBox="0 0 16 16">
                                        <path d="M10.5 8.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0" />
                                        <path d="M2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4zm.5 2a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1m9 2.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0" />
                                    </svg>
                                </div>
                            </div> */}
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                        </div>
                        <div className='add_btn' onClick={openINput}  >
                            <img src={require("../assets/plus.png")} width={16} />
                            {/* <img src={require('../assets/arrow_up.png')} alt='Send button' /> */}
                        </div>
                        <div className='add_btn_text'>
                            <div>Add</div>
                        </div>
                        <input type='file' ref={inputref} style={{ display: 'none' }} onChange={(e) => onSelectFiles(e.target.files)} />
                        <div style={{ margin: "0 10px", overflow: 'hidden', flex: 1, position: 'relative', borderRadius: "25px", border: (fileToUpload == null) ? 'none' : '1px solid rgba(30, 30, 30, 0.4)', borderBottom: fileToUpload != null ? "0px" : "1px" }} >
                            {(fileToUpload != null) &&
                                <div style={{ display: 'flex', background: '#fff', overflow: 'scroll' }}>
                                    {fileToUpload != null && typeof fileToUpload != 'string' ? Array.from(fileToUpload).map((e, index) => {
                                        if (isImage(e.name)) {
                                            return (
                                                <div key={index} style={{ position: 'sticky', bottom: 47, padding: 10, }}>
                                                    <div style={{ width: 'fit-content', position: 'relative', padding: 10, backgroundColor: 'rgba(232, 236, 239, 0.4)', borderRadius: 10, overflow: 'hidden' }} >
                                                        <div onClick={() => removeFile(index)} style={{ position: 'absolute', right: 4, top: 4, width: 20, height: 20, fontSize: 12, textAlign: 'center', cursor: 'pointer', backgroundColor: 'rgba(0,0,0, 0.5)', borderRadius: '50%' }}>
                                                            <img src={require('../assets/cross.png')} style={{ width: 8, height: 8 }} />
                                                        </div>
                                                        {
                                                            e.name ?
                                                                <img alt="preview image" className='input_field_selected_image' src={URL.createObjectURL(e)} />
                                                                :
                                                                <img alt="preview image" className='input_field_selected_image' src={e} />
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        } else if (isVideo(e.name)) {
                                            return (
                                                <div key={index} style={{ position: 'sticky', bottom: 47, padding: 10, }}>
                                                    <div style={{ width: 'fit-content', position: 'relative', padding: 10, backgroundColor: 'rgba(232, 236, 239, 0.4)', borderRadius: 10, overflow: 'hidden' }} >
                                                        <div onClick={() => removeFile(index)} style={{ position: 'absolute', right: 4, top: 4, width: 20, height: 20, fontSize: 12, textAlign: 'center', cursor: 'pointer', backgroundColor: 'rgba(0,0,0, 0.5)', borderRadius: '50%' }}>
                                                            <img src={require('../assets/cross.png')} style={{ width: 8, height: 8 }} />
                                                        </div>
                                                        {
                                                            e.name ?
                                                                <video className='input_field_selected_video'>
                                                                    <source alt="preview image" src={URL.createObjectURL(e)} />
                                                                </video>
                                                                :
                                                                <video className='input_field_selected_video' >
                                                                    <source alt="preview image" style={{ width: '130px', height: '130px', objectFit: 'cover' }} src={e} />
                                                                </video>
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        } else {
                                            return (
                                                <div key={index} style={{ position: 'sticky', bottom: 47, padding: 10, }}>
                                                    <div style={{ width: 'fit-content', position: 'relative', padding: 10, backgroundColor: 'rgba(232, 236, 239, 0.4)', borderRadius: 10, overflow: 'hidden' }} >
                                                        <div onClick={() => removeFile(index)} style={{ position: 'absolute', right: 4, top: 4, width: 20, height: 20, fontSize: 12, textAlign: 'center', cursor: 'pointer', backgroundColor: 'rgba(0 , 0, 0, 0.5)', borderRadius: '50%' }}>
                                                            <img src={require('../assets/cross.png')} style={{ width: 8, height: 8 }} />
                                                        </div>
                                                        {/* <img alt="preview image" style={{ width: '130px', height: '130px' }} src={URL.createObjectURL(e)} /> */}
                                                        <div className='input_field_selected_file' >
                                                            <div style={{ height: '60px' }}  >
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="currentColor" className="bi bi-file-earmark" viewBox="0 0 16 16">
                                                                    <path d="M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5z" />
                                                                </svg>
                                                            </div>
                                                            {e.name}
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    }) :
                                        <div style={{ position: 'sticky', bottom: 47, padding: 10, }}>
                                            <div style={{ width: 'fit-content', position: 'relative', padding: 10, backgroundColor: 'rgba(232, 236, 239, 0.4)', borderRadius: 10, overflow: 'hidden' }} >
                                                <div onClick={() => removeFile()} style={{ position: 'absolute', right: 4, top: 4, width: 20, height: 20, fontSize: 12, textAlign: 'center', cursor: 'pointer', backgroundColor: 'rgba(0,0,0, 0.5)', borderRadius: '50%' }}>
                                                    <img src={require('../assets/cross.png')} style={{ width: 8, height: 8 }} />
                                                </div>
                                                <img alt="preview image" style={{ width: '130px', height: '130px', objectFit: 'cover' }} src={fileToUpload} />
                                            </div>
                                        </div>
                                    }
                                </div>
                            }
                            <input placeholder='Message' value={message} onChange={(e) => setMessage(e.target.value)} />
                        </div>
                        <div className='chat_btn' onClick={send}  >
                            {varient == "homepage" ? <img src={require('../assets/send.png')} alt='Send button' style={{ maxWidth: '20px', objectFit: 'contain' }} /> : <img src={require('../assets/arrow_up.png')} alt='Send button' />}
                        </div>
                    </div>
                    {(input != 'static' || varient == 'homepage') && <div style={{ textAlign: 'center', width: 'fit-content', margin: 'auto', display: 'flex' }}>
                        {/* <Buttons variant='' title={"Generate Report"} onClick={() => alert('From here you will be able to generate report!')} /> */}
                        <div className='mx-4'>
                            <Buttons disabled={parseInt(percentage) <= 75} loading={questCreating} variant='transparent' title={"Start Battle"} onClick={handleBattle} />
                        </div>
                    </div>}
                </form>
            }
        </div >
    )
}

export default ChatComponent