
import React from 'react';
import { Helmet } from 'react-helmet';
import Footer from '../components/footer';

const Insights = () => {
  return (
    <div className='' >
      <Helmet>
        <title>Nestheads - Insight</title>
        <meta name="description" content="Insight description" />
        {/* Other head tags */}
      </Helmet>
      <div className='page_container text-center h-100' >
        <div className='fs-3'>Insights</div>
      </div>
      <Footer />

    </div>
  );
};

export default Insights
  ;