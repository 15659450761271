import React, { useRef, useEffect, useState } from "react";
import InputField from "./InputField";
import Buttons from "./Buttons";

const AddHabit = (props) => {

    const { showPopup, setShowPopup, onConfirm, title } = props

    const popupRef = useRef(null);
    const contentref = useRef(null)

    const [errors, setErrors] = useState({})
    const [time, setTime] = useState(1)
    const [fieldValues, setFieldVlues] = useState({ time: time })

    const onChange = (key, val) => {
        // console.log(key, val)
        let _vals = { ...fieldValues }
        let _errors = { ...errors }
        _vals[key] = val
        delete _errors[key]
        if (val.trim() == "") {
            delete _vals[key]
        }
        setFieldVlues(_vals)
        setErrors(_errors)
        // setFieldVlues(false)
    }

    const handleAdd = () => {
        let _fieldsdata = { ...fieldValues }
        setFieldVlues({})
        setTime(1)
        onConfirm(_fieldsdata[title.toLowerCase() + "_title"])
    }

    useEffect(() => {

        const handleClickOutside = (event) => {
            if (contentref.current && contentref.current.contains(event.target)) {
            } else {
                if (popupRef.current && popupRef.current.contains(event.target)) {
                    setShowPopup(false);
                }
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    return (
        <div>
            {showPopup && (
                <div
                    ref={popupRef}
                    className="popup_backdrop"
                >
                    <div ref={contentref} className='custom_model_quest' >
                        <div style={{ width: "100%" }} >
                            <div style={{ fontSize: '30px', fontFamily: 'Dimbo', }}>Add {title}</div>
                            <div>
                                <InputField lable={title + " title"} placeholder={`Enter ${title} Title`} onChange={onChange} dataKey={title.toLowerCase() + "_title"} />
                            </div>

                            <div className="custom_model_buttons_container">
                                <div style={{ flex: 1, margin: '0 5px' }}>
                                    <Buttons title={'Cancel'} variant='transparent' onClick={() => onConfirm(false)} />
                                </div>
                                <div style={{ flex: 1, margin: '0 5px' }}>
                                    <Buttons title={'Add'} onClick={() => handleAdd()} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
            }
        </div>
    )
}

export default AddHabit